import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

import { BACKGROUND_IMAGE_QUALITY } from '@/constants';

import AspectRatioPaddingBox from '../AspectRatioPaddingBox';
import ImageWrapper from '../ImageWrapper';
import TAGButton from '../TAGButton';

import {
	getInnerWrapperStyles,
	wrapper,
	contentStyles,
	contentWrapperStyles,
	buttonWrapperStyles,
	backgroundImageStyles,
	getBodyStyles,
	getHeadingStyles,
	getSubtitleStyles,
} from './ErrorPage.styles';

export interface IErrorPage {
	heading: string;
	buttonLabel: string;
	body: string;
	subtitle: string;
	backgroundImageUrl: string;
	backgroundImageUrlMobile: string;
	darkTextColor?: boolean;
}

export default function ErrorPage({
	heading,
	body,
	subtitle,
	buttonLabel,
	backgroundImageUrl,
	backgroundImageUrlMobile,
	darkTextColor,
}: IErrorPage) {
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box component="section" data-test-id="section_errorPage" sx={wrapper}>
			<Box
				sx={getInnerWrapperStyles({
					theme,
					templateWidth: 'Full',
					shouldApplyDarkOverlay: false,
					contentPositionSmallScreen: 'Bottom',
				})}
			>
				<AspectRatioPaddingBox contentfulAspectRatio="1/2">
					<ImageWrapper
						style={backgroundImageStyles}
						src={isSmallDevice ? backgroundImageUrlMobile : backgroundImageUrl}
						alt="errorPage_background_image"
						data-test-id="img_errorPage_background"
						layout="fill"
						quality={BACKGROUND_IMAGE_QUALITY}
						priority="true"
					/>
				</AspectRatioPaddingBox>

				<Box data-test-id="section_errorPage_content_wrapper" sx={contentWrapperStyles}>
					<Box data-test-id="section_errorPage_content" sx={contentStyles}>
						{heading && (
							<Box>
								<Typography
									variant={isSmallDevice ? 'heroMedium' : 'heroLarge'}
									whiteSpace="pre-line"
									data-test-id="text_errorPage_heading"
									sx={getHeadingStyles({
										isSmallDevice,
										theme,
										darkTextColor,
									})}
								>
									{heading}
								</Typography>
							</Box>
						)}
						{subtitle && (
							<Box>
								<Typography
									variant="header3"
									whiteSpace="pre-line"
									data-test-id="text_errorPage_subtitle"
									sx={getSubtitleStyles({
										isSmallDevice,
										theme,
										darkTextColor,
									})}
								>
									{subtitle}
								</Typography>
							</Box>
						)}
						{body && (
							<Box>
								<Typography
									variant="header4"
									whiteSpace="pre-line"
									data-test-id="text_errorPage_subtitle_body"
									sx={getBodyStyles({
										theme,
										darkTextColor,
									})}
								>
									{body}
								</Typography>
							</Box>
						)}
						<Box data-test-id="group_errorPage_buttons_wrapper" sx={buttonWrapperStyles}>
							<TAGButton
								fullWidth={isSmallDevice}
								href="/"
								dataTestId="button_error_page"
								size="M"
								variant={darkTextColor ? 'secondaryDefault' : 'secondaryHC'}
							>
								{buttonLabel}
							</TAGButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
