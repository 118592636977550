import { SxProps, Theme } from '@mui/material';

import { Maybe } from '@/types';

export const buttonWrapperStyles = {
	display: 'flex',
	flexWrap: { xs: 'wrap', md: 'nowrap' },
	gap: { xs: '0.5rem', md: '1rem' },
	'& > a, & > button': {
		maxWidth: { xs: '100%', md: 'max-content' },
	},
	justifyContent: 'flex-start',
	marginTop: '2.75rem',
};

export const wrapper = {
	position: { xs: 'relative', md: 'relative' },
	maxWidth: '120rem',
	width: '100%',
	margin: '0 auto',
	maxHeight: '100vh',
	display: 'block',
	overflow: { xs: 'auto', md: 'hidden' },
	left: { xs: 0 },
	height: { xs: '50rem' },
};

export const contentWrapperStyles = {
	padding: '8% 5% 5% 5%',
	marginLeft: 'auto',
	boxSizing: 'border-box',
	marginRight: 'auto',
	position: 'absolute',
	width: '100%',
	maxWidth: '100%',
	zIndex: { xs: 1, md: 5 },
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
	height: '100%',
	aspectRatio: '2/1',
	top: 0,
};

export const contentStyles = {
	height: 'auto',
	paddingBottom: '1.5rem',
	width: '100%',
	maxWidth: '55rem',
	textAlign: 'left',
	paddingLeft: '0px',
	paddingRight: '0px',
};

export const getHeadingStyles = ({
	theme,
	darkTextColor,
	isSmallDevice,
}: {
	theme: Theme;
	darkTextColor: boolean | undefined;
	isSmallDevice: boolean;
}): SxProps => ({
	maxWidth: '100%',
	paddingBottom: isSmallDevice ? '0.5rem' : '1.25rem',
	paddingLeft: 0,
	display: 'block',
	textAlign: 'left',
	color: darkTextColor ? theme.palette.primary.main : theme.palette.text.light,
	backgroundColor: 'transparent',
	margin: '0 auto',
});

export const getSubtitleStyles = ({
	theme,
	darkTextColor,
	isSmallDevice,
}: {
	theme: Theme;
	darkTextColor: boolean | undefined;
	isSmallDevice: boolean;
}): SxProps => ({
	color: darkTextColor ? theme.palette.primary.main : theme.palette.text.light,
	paddingBottom: isSmallDevice ? '0.5rem' : '1.25rem',
});

export const getBodyStyles = ({
	theme,
	darkTextColor,
}: {
	theme: Theme;
	darkTextColor: boolean | undefined;
}): SxProps => ({
	color: darkTextColor ? theme.palette.primary.main : theme.palette.text.light,
});

export const backgroundImageStyles = {
	display: 'block',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	height: 'auto',
	width: '100%',
	overflow: 'hidden',
	objectFit: 'cover',
};

export const getInnerWrapperStyles = ({
	theme,
	templateWidth,
	shouldApplyDarkOverlay,
	contentPositionSmallScreen,
}: {
	theme: Theme;
	templateWidth?: Maybe<string>;
	shouldApplyDarkOverlay: boolean;
	contentPositionSmallScreen?: Maybe<string>;
}): SxProps => ({
	[theme.breakpoints.down('md')]: { paddingLeft: '0', paddingRight: '0' },
	width: templateWidth === 'Inset' ? '90%' : '100%',
	margin: '0 auto',
	position: 'relative',
	display: 'flex',
	flexDirection: { xs: contentPositionSmallScreen === 'Top' ? 'column-reverse' : 'column', md: 'column' },
	maxHeight: '60rem',
	aspectRatio: '2/1',
	'&:after': {
		content: '""',
		width: '100%',
		height: '100%',
		backgroundImage: shouldApplyDarkOverlay ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' : '',
		position: 'absolute',
		right: 0,
		top: 0,
		zIndex: 1,
	},
});
